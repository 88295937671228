/* tailwind base to avoid overwritting theme resets */
@tailwind base;

@layer base {

  main[role="main"] {
    width: 100%;
    @apply p-4;
  }
  
  @screen lg {
    main[role="main"] {
      max-width: theme('screens.lg');
      @apply mx-auto;
      @apply px-0;
    }
  }
  
  @screen xl {
    main[role="main"] {
      max-width: theme('screens.xl');
      @apply mx-auto;
      @apply px-0;
    }
  }
  
}
